.left-side-menu {
  margin-top: 30px;
  // width: 30%;
  width: 300px;
  min-width: 70px;
  font-family: "Poppins";

  // .slimscroll-menu :nth-child(1) {
  //   overflow: unset !important;
  // }
  
  .logo {
    width: 300px;
    .logo-lg {
      width: 300px;
      margin-top: 5px;
    }
  }
  .active {
    * {
      color: white !important;
    }
  }


}
.content-page {
  @media (min-width: 1028px) {
    margin-left: 300px;
    font-family: "Poppins";

  }
  // margin-left: 300px!important;

  .navbar-custom {
    position: 0;
    // margin-left: 300px;
    left: 300px;
    @media (max-width: 770px) {
      margin-left: 0px;

    }
  }
}
// .content {
//   padding-left: 20px;
//   padding-right: 20px
// }

.themeSwitcher {
  position: relative;
  top: calc(38px / 2);

  .moon::before {
    content: "\1F31C";
  }

  .sun::before {
    content: "\1F31E";
	}
  .toggle_icon {
    align-items: center;
    display: flex;
    height: 10px;
    justify-content: center;
    position: relative;
    width: 10px
	}


}

// .profile-list{
//   .account-user-avatar img{
//     width: 40px!important;
//   }
// }

