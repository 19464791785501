//
// page-head.scss
//

.page-title-box {
  .page-title {
      font-size: 2rem;
      margin: 0;
      line-height: 85px;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
     
  }
  .page-title-right {
      float: right;
      margin-top: 25px;
  }

  .breadcrumb {
      padding-top: 8px;
  }
}
.toasts-container{
    text-align: center !important;
    .toast-error{
        width: 420px !important;
        display: table-cell;
        max-height: 45px!important;
        padding-top: 15px;
    }
}


.page-title-box-sm {
  .page-title {
      line-height: 1 !important;
      margin-bottom: 25px;
  }
  .page-title-right {
      float: right;
      margin-top: 0;
  }

  .breadcrumb {
      padding-top: 0;
      margin-top: -3px !important;
  }
}

* {
    transition: 0s!important;
}