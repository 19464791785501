.profile-header {
    font-size: 32px;
    font-family: "Poppins";
    font-weight: 600;
    margin: 20px;
}

.userinfo {
    padding: 10px;
    // img{
    //     // height: 5.5rem;
    //     width: 7rem;
    // }
}

.mark {
    display: block;
    position: absolute;
    top: 50px;
    width: 55px;
    text-align: center;
    transform: rotate(-45deg);
    padding: 1px 3px;
    border-radius: 3px;
    color: #fff;
    margin-left: -10px;
}

.credit-chart {
    margin: 0 auto;
}

.toxic-mark {
    background-color: #F4725C;
}

.healthy-mark {
    background-color: #69CE72;
}

.irs--flat .irs-line {
    background: linear-gradient(90deg, #F4725C 50%, #69CE72 50%);
}

.toxic-range-slider .irs-bar {
    background-color: #98a6ad !important;
}

.update-ranking {
    margin-top: 60px;
}

.update-plan {
    .plan-period{
        margin-top: 20px;
        // font-size: 20px;
    }
    .checkbox {
        margin: auto;
        margin-left: 20px;
        width: 80px;
        // font-size: 16px;
        .form-check-input {
            margin-right: 10px;
        }
    }

}