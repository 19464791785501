.custom-modal-style {
  // width: 1097px;
  // height: 700px;
  margin-right: 0 !important;
  margin-top: 0 !important;
  // border-radius: 10px !important;

  td {
    padding: 0px;
  }

  .modal-content {
    border-radius: 10px !important;
    .modal-header {
      .width-option {
        position: absolute;
        right: 100px;
        top: 25px;
      }
    }

    .header-style {
      margin-left: 15px;
      margin-right: 15px;
    }

    .modal-body {
      .appex-chart {
        .apexcharts-tooltip {
          .apexcharts-tooltip-series-group {
            font-family: "Poppins" !important;
            color: #ffffff;
            font-size: 21px;
            background-color: #394756 !important;

            .mdi-circle {
              margin-right: 4px;
            }
          }

          .apexcharts-tooltip-title {
            font-family: "Poppins";
            color: #ffffff;
            border-bottom: 0px !important;
            margin-bottom: 0px;

            background-color: #394756 !important;
          }
        }
      }
      .col-md-8 {
        .table {
          td {
            font-size: 15px;
          }
        }
      }
    }
  }
}

.dot-dropdown-toggle {
  font-size: 1.3rem;
}

.apexcharts-datalabel-value {
  color: #000108 !important;
  font-weight: 800 !important;
  font-size: 30px !important;
}

.credit-chart {
  .apexcharts-pie {
    .apexcharts-datalabel-label {
      vertical-align: text-top;
      margin-top: 20px;
      vertical-align: top;
      font-family: "Poppins";
      font-weight: 600;
    }

    .apexcharts-datalabel-value {
      display: none;
    }
  }
}

.add-note {
  color: #e8526d;
  background-color: transparent;
  border: 1px solid #dfe1e6;
  width: 117px;
  height: 40px;
  vertical-align: middle;
  font-style: "Poppins";
  font-size: 15px;
  font-weight: 500;
}

.btn-primary {
  color: #fff;
  background-color: #727cf5;
  border-color: #727cf5;
}

.btn-primary:hover {
  color: #fff;
  background-color: #4e5bf2;
  border-color: #4250f2;
}

.btn-primary:focus,
.btn-primary.focus {
  color: #fff;
  background-color: #4e5bf2;
  border-color: #4250f2;
  -webkit-box-shadow: 0 0 0 0.2rem rgba(135, 144, 247, 0.5);
  box-shadow: 0 0 0 0.2rem rgba(135, 144, 247, 0.5);
}

.btn-primary.disabled,
.btn-primary:disabled {
  color: #fff;
  background-color: #727cf5;
  border-color: #727cf5;
}

.btn-primary:not(:disabled):not(.disabled):active,
.btn-primary:not(:disabled):not(.disabled).active,
.show > .btn-primary.dropdown-toggle {
  color: #fff;
  background-color: #4250f2;
  border-color: #3645f1;
}

.btn-primary:not(:disabled):not(.disabled):active:focus,
.btn-primary:not(:disabled):not(.disabled).active:focus,
.show > .btn-primary.dropdown-toggle:focus {
  -webkit-box-shadow: 0 0 0 0.2rem rgba(135, 144, 247, 0.5);
  box-shadow: 0 0 0 0.2rem rgba(135, 144, 247, 0.5);
}

.btn-danger {
  color: #fff;
  background-color: #fa5c7c;
  border-color: #fa5c7c;
}

.btn-danger:hover {
  color: #fff;
  background-color: #f9375e;
  border-color: #f82b54;
}

.btn-danger:focus,
.btn-danger.focus {
  color: #fff;
  background-color: #f9375e;
  border-color: #f82b54;
  -webkit-box-shadow: 0 0 0 0.2rem rgba(251, 116, 144, 0.5);
  box-shadow: 0 0 0 0.2rem rgba(251, 116, 144, 0.5);
}

.btn-danger.disabled,
.btn-danger:disabled {
  color: #fff;
  background-color: #fa5c7c;
  border-color: #fa5c7c;
}

.btn-danger:not(:disabled):not(.disabled):active,
.btn-danger:not(:disabled):not(.disabled).active,
.show > .btn-danger.dropdown-toggle {
  color: #fff;
  background-color: #f82b54;
  border-color: #f81e4a;
}

.btn-danger:not(:disabled):not(.disabled):active:focus,
.btn-danger:not(:disabled):not(.disabled).active:focus,
.show > .btn-danger.dropdown-toggle:focus {
  -webkit-box-shadow: 0 0 0 0.2rem rgba(251, 116, 144, 0.5);
  box-shadow: 0 0 0 0.2rem rgba(251, 116, 144, 0.5);
}

.toggle-container {
  width: 109px;
  background-color: #c4c4c4;
  cursor: pointer;
  user-select: none;
  border-radius: 33px;
  padding: 0px;
  height: 34px;
  position: relative;

  .toggle-button {
    font-size: 14px;
    line-height: 18px;
    font-weight: bold;
    cursor: pointer;
    background-color: #002b49;
    color: white;
    padding: 8px 0px;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    min-width: 36px;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 80px;
    min-width: unset;
    border-radius: 16px;
    box-sizing: border-box;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.25);
    position: absolute;
    left: 28px;
    transition: all 0.5s ease;
  }
  .disabled {
    // background-color: #707070;
    background-color: #00c853;
    left: 1px;
  }
}
