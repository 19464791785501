// .row {
//     padding-right: 15px !important;
//     padding-left: 15px !important;
//   }
.page-title{
  font-weight: 600 !important;
  font-family:'Poppins'!important;
}
.dashboard_chart_icon {
  color: #727cf5;
}
#dashboardWrapper {
  font-family: "Poppins";
  
  padding: 10px 25px;

  .container-fluid {
    margin-bottom: 30px;
  }

  .infoWrapper {
    margin-top: 15px;
    background-color: white;
    border-radius: 5px;
    min-height: 60px;

    .infoCard {
      padding: 25px 0;
      display: flex;
      flex-direction: column;
      justify-content: center;
      height: 150px;
      align-items: center;

      i {
        color: rgb(172, 172, 172);
      }

      .numericLabel {
        margin-top: 0px;
        font-size: 1.9rem;
      }

      .textLabel {
        color: rgb(172, 172, 172);
        margin-bottom: -10px;

      }
    }
  }

  .performanceData {
    display: flex;
    justify-content: center;
    align-items: center;

    .text {
      margin-right: 10px;
    }

    .icon {
      i {
        color: rgb(0, 231, 0) !important;
      }

      font-size: 1.5rem;
    }
  }

  .green {
    color: rgb(0, 231, 0) !important;
  }

  .red {
    color: rgb(255, 23, 35) !important;
  }

  .black {
    color: rgb(42, 42, 42) !important;
  }

  .text-danger {
    color: #fa5c7c!important;
  }

  .grey {
    color: rgb(172, 172, 172) !important;
  }
}

.loader {
  --path: #2F3545;
  --dot: #5628EE;
  --duration: 1.5s;
  width: 44px;
  height: 44px;
  position: relative;

  &:before {
    content: '';
    width: 6px;
    height: 6px;
    border-radius: 50%;
    position: absolute;
    display: block;
    background: var(--dot);
    top: 37px;
    left: 19px;
    transform: translate(-18px, -18px);
    animation: dotRect var(--duration) cubic-bezier(0.785, 0.135, 0.15, 0.86) infinite;
  }

  svg {
    display: block;
    width: 100%;
    height: 100%;

    rect,
    polygon,
    circle {
      fill: none;
      stroke: var(--path);
      stroke-width: 5px;
      stroke-linejoin: round;
      stroke-linecap: round;
    }

    polygon {
      stroke-dasharray: 145 (221 - 145) 145 (221 - 145);
      stroke-dashoffset: 0;
      animation: pathTriangle var(--duration) cubic-bezier(0.785, 0.135, 0.15, 0.86) infinite;
    }

    rect {
      stroke-dasharray: (256 / 4 * 3) (256 / 4) (256 / 4 * 3) (256 / 4);
      stroke-dashoffset: 0;
      animation: pathRect 3s cubic-bezier(0.785, 0.135, 0.15, 0.86) infinite;
    }

    circle {
      stroke-dasharray: (200 / 4 * 3) (200 / 4) (200 / 4 * 3) (200 / 4);
      stroke-dashoffset: 75;
      animation: pathCircle var(--duration) cubic-bezier(0.785, 0.135, 0.15, 0.86) infinite;
    }
  }

  &.triangle {
    width: 48px;

    &:before {
      left: 21px;
      transform: translate(-10px, -18px);
      animation: dotTriangle var(--duration) cubic-bezier(0.785, 0.135, 0.15, 0.86) infinite;
    }
  }
}

@keyframes pathTriangle {
  33% {
    stroke-dashoffset: 74;
  }

  66% {
    stroke-dashoffset: 147;
  }

  100% {
    stroke-dashoffset: 221;
  }
}

@keyframes dotTriangle {
  33% {
    transform: translate(0, 0);
  }

  66% {
    transform: translate(10px, -18px);
  }

  100% {
    transform: translate(-10px, -18px);
  }
}

@keyframes pathRect {
  25% {
    stroke-dashoffset: 64;
  }

  50% {
    stroke-dashoffset: 128;
  }

  75% {
    stroke-dashoffset: 192;
  }

  100% {
    stroke-dashoffset: 256;
  }
}

@keyframes dotRect {
  25% {
    transform: translate(0, 0);
  }

  50% {
    transform: translate(18px, -18px);
  }

  75% {
    transform: translate(0, -36px);
  }

  100% {
    transform: translate(-18px, -18px);
  }
}

@keyframes pathCircle {
  25% {
    stroke-dashoffset: 125;
  }

  50% {
    stroke-dashoffset: 175;
  }

  75% {
    stroke-dashoffset: 225;
  }

  100% {
    stroke-dashoffset: 275;
  }
}

.loader {
  display: inline-block;
  margin: 0 16px;
}

* {
  box-sizing: border-box;

  &:before,
  &:after {
    box-sizing: border-box;
  }
}

.no-padding{
  padding-right: 0px !important;
  padding-left: 0px !important;
}

table {
  border-collapse:separate;
  border-spacing: 0 1em;
 
}
table th {
  // color: #8ca0b3;
  border-bottom: none !important;
  border-top: 1px;
}

td {
  border: none;
  vertical-align: middle !important;
  font-size: 1.2em;
  font-weight: 1200;
}

thead {
  line-height: 60px;
  font-size: 1.2em;
  border: none;
}

tbody tr:hover {
  cursor: pointer !important;
}
.table-sm td, .table-sm th {padding: .3rem}