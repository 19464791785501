.pageheader {
  margin-left: 0px;
  margin-right: 0px;
  margin-top: 15px;
  margin-bottom: 15px;
}

.period {
  max-width: 200px;
  position: relative;
  margin-top: 20px;
  margin-bottom: 20px;
  left: 10px;

  .period-label {
    min-width: 100px;
    padding: 8px;
  }
}

.labs-card {
  font-family: "Poppins";
  font-style: normal;

  padding-left: 8px;
  padding-right: 8px;
  padding-bottom: 5px;
  margin-left: 8px;
  margin-right: 8px;
  cursor: pointer;

  .dot-dropdown-toggle {
    position: absolute;
    //    margin-top: 10px;
    right: 10px;
    top: 0px;
    z-index: 99;
    display: inline;
    font-size: 24px;
    color: #a5adba;
    // .dropdown-menu{

    // }
  }

  .labs-card-row {
    .position {
      padding: 10px 0px;
      .position-label {
        font-size: 17px !important;
        font-weight: 600;
      }

      span {
        font-size: 14px;
      }
    }

    // font-size: 14px;

    .campaign-name {
      font-size: 26px !important;
    }

    .campaign-running {
      font-size: 18px !important;
      padding-bottom: 20px;
    }

    .average-position-value {
      font-size: 18px;
      // padding: 5px;
    }

    .labs-value,
    .keywords-value {
      padding-right: 0px;
      padding-left: 30px;
      font-size: 18px;
    }

    hr {
      margin-top: 4px;
      margin-bottom: 4px;
    }

    .position-range {
      .range-value {
        padding-right: 0px;
        padding-left: 40px !important;
      }
    }
  }
}

.lab-detail-summary {
  font-family: "Poppins" !important;
  .col-md-4 {
    // max-height: 370px !important;
    flex-wrap: wrap !important;
    // overflow: hidden;
    .summary-label {
      font-size: 15px;
      font-style: normal;
      font-weight: 500;
      text-align: left;
    }

    .summary-value {
      overflow: hidden;
      font-size: 24px !important;
      font-style: normal;
      font-weight: 500;
      text-align: left;
      max-height: 40px;
    }

    .position-value {
      font-size: 18px;
      font-style: normal;
      font-weight: 500;
      text-align: left;
      padding-right: 0px;
      padding-left: 20px;
    }
    .lab-summary {
      margin-bottom: 10px;
      .position-label {
        font-size: 24px;
        font-style: normal;
        font-weight: 600;
        text-align: left;
      }
    }
  }
}

.labs-table {
  .sortable-table {
    thead {
      th {
        text-align: center !important;
      }
    }
    tbody {
      td {
        text-align: center !important;
        .action-icon {
          font-size: 1.4rem;
        }
      }
    }
  }
}

.modal-dialog {
  .modal-body {
    .form-group {
      .form-check {
        font-size: 1.2rem;
        .form-check-input {
          margin-top: 0.6rem;
        }
      }
    }
  }
}

.lab-multiple-charts {
  // .apexcharts-tooltip {
  //   color: white;
  //   font-family: "Poppins";
  //   background: #394756;

  //   .apexcharts-tooltip-title {
  //     background: #394756;
  //   }
  // }

  .apexcharts-legend {
    font-weight: 600;
  }
  .apexcharts-tooltip {
    .apexcharts-tooltip-series-group {
      font-family: "Poppins" !important;
      color: #ffffff;
      font-size: 21px;
      background-color: #394756;

      .mdi-circle {
        margin-right: 4px;
      }
    }

    .apexcharts-tooltip-title {
      font-family: "Poppins";
      color: #ffffff;
      border-bottom: 0px !important;
      margin-bottom: 0px;

      background-color: #394756 !important;
    }
  }

  // .apexcharts-xaxis-annotation-label {
  //   cursor: pointer !important;
  // }
}
