.loginHeader {
  font-size: 1.5rem;
  margin: 22px 0;
  color: white;
}

.error-field {
  color: red;
  font-size: 0.7rem;
  margin-top: -8px;
  margin-bottom: 14px;
}