.pageheader {
  font-family: "Poppins" !important;
  .col-md-6 {
    .page-title-box {
      font-size: 32px !important;
      font-weight: 600 !important;

      margin-left: -6px !important;
      margin-top: 6px !important;
    }
  }

  .col-md-6 {
    padding: 0px;
    .page-title-right {
      .createbtn {
        i {
          margin-right: 10px;
          height: 24px !important;
        }
        margin-top: 16px;
        margin-left: 16px;
        margin-bottom: 16px;
        height: 40px;
        // width: 170px !important;
        font-size: 15px !important;

        font-weight: 500 !important;
        letter-spacing: 0.03em !important;
        text-align: center;
        border-color: transparent;
      }
    }
  }
}

.pageform {
  font-family: "Poppins";
  @media (max-width: 700px) {
    .formgroup {
      max-width: 150px;
    }

    .col-form-label {
      padding-left: 15px;
    }
  }
}

.keyword-campaign {
  cursor: pointer;
  font-family: "Poppins";
  .card-body {
    padding-left: 1px !important;
    font-family: "Poppins";
    padding-top: 18px;
    padding-right: 1px;

    @media (max-width: 768px) {
      margin-left: 50px;
    }

    .mb-content {
      @media (max-width: 650px) {
        padding-right: 0px;
        margin-right: 0px;

        max-width: calc(100% - 60px);
      }

      .project-title {
        font-weight: 600;
        font-size: 20px;
        //    color: #253858;
      }

      .project-domain {
        font-size: 16px;
        font-weight: 400;
        margin-bottom: 16px;
      }

      .average-position-label {
        font-size: 15px;
        font-weight: 500;
        // margin-bottom: 15px;
      }

      .average-position-value {
        margin-bottom: 14px;
        padding-bottom: 10px;
      }

      .average-position-value :nth-child(1) {
        font-size: 28px;
        font-weight: 500;
        margin-right: 8px;
      }

      .average-position-value :nth-child(3) {
        font-size: 16px;
        font-weight: 500;
        margin-right: 4px;

        color: #36b37e;
      }

      .last-updated {
        font-weight: 400;
        font-size: 13px;
        margin-top: 10px;
        margin-bottom: 10px;
      }

      .separate {
        padding-right: 0px !important;

        #verticle-line {
          @media (max-width: 800px) {
            display: none;
          }

          width: 1px;
          min-height: 200px;
          background: lightgrey;
          font-weight: 100;
        }
      }
    }

    .mb-position {
      padding-top: 8px;
      padding-left: 12px;

      .position {
        padding-bottom: 10px;
        padding-left: 12px;

        .position-label {
          font-size: 15px;
          font-weight: 500;
        }

        .position-value {
          font-size: 30px;
          font-weight: 500;
        }
      }

      .table-responsive {
        padding-top: 20px;
        .table {
          width: auto;
          border: none !important;
          border-spacing: 0px;

          // border: 0px;
          // border-color: transparent;
          tr {
            .range-label {
              // padding-right: 60px;
              text-align: left !important;
            }

            td {
              font-size: 15px;
              border: 0px solid black;
              padding-top: 0px !important;
              padding-bottom: 0px !important;
              text-align: center !important;
              padding-right: 20px;

              @media (min-width: 1560px) {
                padding-right: 40px;
              }
            }
          }
        }
      }
    }

    .mb-keywords {
      padding-top: 8px;
      padding-left: 12px;
      font-family: "Poppins" !important;

      .keywords {
        // padding-bottom: 15px;

        .keywords-label {
          font-size: 15px;
          font-weight: 500;
        }

        .keywords-value {
          font-size: 28px;
          margin-left: 10px;
          font-weight: 500;
        }
      }

      .organic {
        font-size: 13px;
        font-weight: 500;
        margin-bottom: 12px;
        // padding-bottom: 6px;
        // padding-top: 6px;
        // margin-top: 8px;
        font-family: "Poppins" !important;
        .trafic-label {
          padding-top: 30px;
          .organic-trafic-label {
            // padding-top: 8px;
            // padding-right: 65px;
            font-size: 15px;
            font-weight: 500;
            padding-left: 0px;
            padding-right: 0px;
          }
        }

        .organic-trafic-value {
          padding-left: 0px;
          padding-right: 0px;
          font-size: 18px;
          font-weight: 500;
        }
        .keywords-label {
          padding-top: 20px;
          .organic-keywords-label {
            // padding-right: 38px;
            padding-left: 0px;
            padding-right: 0px;
            font-size: 15px;
            font-weight: 500;
          }
        }

        .organic_diff {
          font-size: 12px;
          // color: green;
        }
        .sparklinkchart {
          .apexcharts-yaxis {
            display: none;
          }
        }
      }
    }

    .mb-location {
      font-family: "Poppins";
      padding-top: 8px;
      padding-left: 12px;
      max-width: 400px;
      // @media (max-width: 1700px) {
      //      min-width: calc(100% + 100px);
      // }

      .location {
        padding-bottom: 10px;

        .location-label {
          font-size: 16px;
          padding-bottom: 20px;
          font-weight: 500;
        }

        .location-value {
          font-size: 16px;
          font-weight: 500;
        }
      }

      .updateButton {
        @media (max-width: 1370px) {
          display: none;
        }

        .dot-dropdown-toggle {
          padding-top: 0px !important;
        }
      }
    }

    ._updateButton {
      @media (max-width: 765px) {
        display: none;
      }

      @media (min-width: 1370px) {
        display: none;
      }
    }

    ._updateButton_ {
      @media (min-width: 765px) {
        display: none;
      }
    }
  }
}

.header-1 {
  font-family: "Poppins";
  font-size: 15px;
  margin-top: 16px;
  margin-bottom: 16px;
  padding-bottom: 8px;
}

.header-2 {
  font-family: "Poppins";

  padding-bottom: 22px;

  .title {
    font-size: 32px;
    font-weight: 700;
  }

  .period {
    position: 50px;
    max-width: 250px;
    min-width: 200px;
    z-index: 99;
    // padding-right: 20px;
    display: inline;
    font-size: 18px;
    color: #a5adba;
    left: 10px;

    .form-control {
      min-width: 120px;
      position: 20px;
      margin-left: 10px;
    }
  }
}

.header-3 {
  margin-bottom: 16px;
  font-family: "Poppins";

  .content {
    padding: 16px;
    margin: 0px;

    .average-style {
      font-size: 15px;
      font-weight: 500;
    }

    .average-value {
      font-size: 28px;
      font-weight: 500;
      margin-top: 6px;
    }
    .sparklinkchart {
      margin-top: 20px;
      .apexcharts-yaxis {
        display: none;
      }
      // width: 200px;
    }
  }
}

.noshadow {
  .uil-trash-alt {
    position: absolute;
    margin-top: -10px;
    right: 20px;
    z-index: 99;
    display: inline;
    font-size: 24px;
    color: #a5adba;
  }
}

.uil-trash-alt {
  display: none;
}

.keywords-table {
  .table-responsive {
    padding-right: 1em;
    padding-left: 1em;
    padding-bottom: 1em;
    padding-top: 1em;
    font-family: "Poppins";
    // border-spacing: 0px;
    .table {
      -webkit-border-vertical-spacing: 0em;
      -webkit-border-horizontal-spacing: 0px;
      input.table-checkbox {
        width: 16px;
        height: 16px;
      }
      font-size: 13px;

      thead.detailtablehead {
        tr {
          font-family: "Poppins";
          .organic_keywords {
            padding-left: 0px;
            padding-right: 0px;
          }
        }
      }
      tbody {
        tr.havertable {
          font-family: "Poppins";
          height: 4.5em;
          th {
            font-weight: 500;
          }

          td {
            width: 150px;
            font-weight: 400;
            // .checkbox {
            //   max-width: 50px !important;
            // }
            .sparklinkchart {
              // padding-right: 5px !important;
              padding-top: 15px;
              padding-bottom: 15px;
              margin-right: 20px;
              margin-left: 20px;
              width: 150px;
              // height: 30px;
              .apexcharts-yaxis {
                display: none;
              }
            }
          }

          .uil-trash-alt {
            display: inline;
            font-size: 20px;
            color: #a5adba;
          }
        }
      }

      tfoot {
        .tfoot-left {
          // max-height: 30px;
          min-width: 330px;
          .page-size {
            min-width: 70px;
          }
        }
      }
    }
  }
}
.custom-button {
  .uil-trash-alt {
    position: absolute;
    margin-right: 10px;
    right: 20px;
    z-index: 99;
    display: inline;
    font-size: 24px;
    color: #a5adba;
  }
  .customize {
    margin-left: 10px;
  }
  .createbtn {
    margin-left: 10px;
  }
}

@media (min-width: 1090) {
  .modal-dialog {
    min-width: calc(100% - 260px);
    padding-bottom: 0px;
    max-height: 80%;

    .modal-header {
      .modal-title {
        min-width: calc(100% - 60px) !important;
        margin-top: 10px;
      }

      .close {
        max-width: 60px;
        padding-top: 25px !important;
      }
    }

    .modal-body {
      min-width: calc(100% - 60px);

      .container-fluid {
        min-width: calc(100% - 60px);

        .content {
          min-width: fit-content;
        }
      }
    }
  }
}

@media (max-width: 750px) {
  .modal-dialog {
    width: 80%;

    //    min-height: 80%;
    .tab-content {
      width: calc(100% - 60px);
      // height: 100%;
    }
  }
}

.modal-content {
  .close {
    margin-top: 5px;
    padding-top: 0px;
    font-size: 28px;
  }

  .table {
    //styleName: Body / 3;
    font-family: "Poppins";
    // font-size: 15px;
    font-style: normal;
    // font-weight: 400;
    // line-height: 20px;
    letter-spacing: 0em;

    // text-align: left;
    .note-content {
      text-align: left;
      font-weight: 400;
      font-size: 15px !important;
    }

    .note-date {
      font-size: 13px;
      font-weight: 400;
      text-align: left;
    }

    .mdi-trash-can-outline {
      font-size: 22px;
      margin-right: 7px;
      color: #a5adba;
    }

    .dripicons-pencil {
      font-size: 15px;
      color: #a5adba;
    }
  }
  .history-ranking {
    -webkit-border-vertical-spacing: 0em;
    -webkit-border-horizontal-spacing: 0px;
    tbody {
      tr {
        height: 2.5em;
        th,
        td {
          padding-left: 10px;
          padding-top: 1px;
          padding-bottom: 1px;
          margin-top: 1px;
          margin-bottom: 1px;
          font-size: 15px;
        }
      }
    }
  }
}

.keyword-ranking-charts {
  .apexcharts-tooltip {
    .apexcharts-tooltip-series-group {
      font-family: "Poppins" !important;
      color: #ffffff;
      font-size: 21px;
      background-color: #394756;

      .mdi-circle {
        margin-right: 4px;
      }
    }

    .apexcharts-tooltip-title {
      font-family: "Poppins";
      color: #ffffff;
      border-bottom: 0px !important;
      margin-bottom: 0px;

      background-color: #394756 !important;
    }
  }

  .apexcharts-legend-marker {
    display: none !important;
  }

  .apexcharts-xaxis-annotation-label {
    cursor: pointer !important;
  }
}
.add-note {
  color: #e8526d;
  background-color: transparent;
  border: 1px solid #dfe1e6;
  width: 157px;
  height: 40px;
  vertical-align: middle;
  font-style: "Poppins";
  font-size: 15px;
  font-weight: 500;
}
