.card {
  .card-body {
    font-family: "Poppins";
    margin-left: 15px;
    margin-right: 15px;
    padding-top: 20px;
    cursor: pointer;
    .apex-charts{
      .apexcharts-datalabels-group :nth-child(1) {
        fill: #344563!important;
        font-family:"Poppins" !important;
        font-size: 15px !important;
        font-weight: 600 !important;
      }
      .apexcharts-text{
        color: "#4543433"!important;
      }
    }
    
  }
}

.updown-card {
  .card-body {
    .updown-data {
      font-size: 28px;
      // padding-top: 5px !important;
    }
    .updown-label{
      font-size: 15px;
    }

    // .arrow {
    //   font-size: 11px;
    //   margin-bottom: 5px!important;
    // }
  }
}

.toxic_period {
  margin-top: 40px;
  margin-bottom: 15px;
}

.campaign_detail {
  margin-top: 15px;
  font-family: "Poppins";
}

hr{
  margin: 8px;
}


.form-row {
  margin-bottom: 20px;

  .col-form-label {
    margin-right: 20px;
  }
}


.backlinks-table {
  padding-right: 16px;
  padding-left: 16px;
  padding-bottom: 16px;
  padding-top: 16px;

  .table {
    -webkit-border-vertical-spacing: 0em;
    text-align: center !important;
    font-family: "Poppins";

    input {
      width: 16px;
      height: 16px;
    }

    tbody {
      tr {
        height: 4.5em;
        th {
          font-weight: 500;
        }

        td {
          a:hover{
            color: lightgrey !important;
          }
          font-weight: 400;
          .action-icon {
            padding: 0px;
          }

        }
      }
    }
    tfoot {
      .text-right{
        padding-right: 10px;
      }
    }
  }

}

